import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Papa from "papaparse";
import moment from "moment";

import styles from './../../TeamList.module.css';
import logo from "../../../assets/fanup_logo_white.png";
import downArrow from '../../../assets/downarrow.svg';

import CustomNavbar from "../../CustomNavbar";
import UpdateLive from "../../UpdatingLive";
import fetchWrapper from "../../../utils/fetchWrapper";

const TeamsPage = ({ tournamentId }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const [teams, setTeams] = useState([]);
  const [expandedTeams, setExpandedTeams] = useState({});
  const [loading, setLoading] = useState(false);

  const toggleTeamExpand = (index) => {
    setExpandedTeams(prevExpandedTeams => ({
      ...prevExpandedTeams,
      [index]: !prevExpandedTeams[index]
    }));
  };

  useEffect(() => {
    if (!tournamentId) {
      console.error("No tournament found");
      navigate("/");
      return;
    }

    const fetchTournamentAndSheetData = async () => {
      try {
        setLoading(true);
        const tournamentResponse = await fetchWrapper(
          `${BACKEND_URL}/tournament/${tournamentId}`,
          {
            credentials: "include",
          }
        );
        if (tournamentResponse.ok) {
          const tournamentData = await tournamentResponse.json();
          //change sheet thing here
          let sheetLink = tournamentData.teams;
          //just for demo  
          // sheetLink = "https://docs.google.com/spreadsheets/d/1Oa89tb3UQIN64mSy3H8yskJscDZ4HrDx8XF2vd2t0Ow/edit?usp=sharing";

          if (sheetLink) {
            const sheetId = extractSheetIdFromLink(sheetLink);
            const sheetName = "Sheet1";
            const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;
            const sheetResponse = await fetchWrapper(url);
            if (sheetResponse.ok) {

              const csvData = await sheetResponse.text();
              const players = parseCSVData(csvData);

              const allTeamsData = [];

              // console.log(players);

              for (const player of players) {
                const playerName = player.playerName;

                let collegeName = player.collegeName;
                if (collegeName != null) {
                  collegeName = collegeName.toString().toLowerCase();


                  const jerseyNo = player.jerseyNo;
                  const email = player.email;
                  const mobileNo = player.mobileNo;

                  // Find existing college
                  let college = allTeamsData.find(c => c.teamName === collegeName);

                  if (!college) {
                    // If college doesn't exist, create a new entry
                    college = { teamName: collegeName, players: [] };
                    allTeamsData.push(college);
                  }

                  // Append player details to the college's players array
                  college.players.push({
                    playerName,
                    jerseyNo,
                    email,
                    mobileNo
                  });

                }

              }

              setTeams(allTeamsData);

              setLoading(false);
            } else {
              throw new Error("Failed to fetch Google Sheet data");
            }
          }

        } else {
          throw new Error("Failed to fetch tournament data");
        }
      } catch (error) {
        console.error("Error fetching tournament or Google Sheet data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentAndSheetData();

    const interval = setInterval(() => {
      fetchTournamentAndSheetData();
    }, 30000);

    return () => clearInterval(interval);
  }, [navigate, location, tournamentId]);

  const extractSheetIdFromLink = (link) => {
    const match = link.match(/\/d\/([a-zA-Z0-9-_]+)/);
    return match ? match[1] : null;
  };

  const parseCSVData = (csv) => {
    const parsedData = Papa.parse(csv, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true
    });
    const entries = [];
    for (const item of parsedData.data) {
      if (item["Player Name"] != null && item["College Name"] != null) {
        entries.push({
          playerName: item["Player Name"].toString().trim(),
          collegeName: item["College Name"].toString().trim(),
          jerseyNo: item["Jersey No."],
          mobileNo: item["Mobile no."],
          email: item["Email"]
        });
      }
    }
    return entries;
  };

  return (
    <div className={`${styles.tournamentPage} ss-page`}>
      {loading && <UpdateLive loadingTxt="Refreshing..." />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt='FanUp Logo' className={styles.logo} />
          <span className={styles.registrationText}>TEAM SHEET</span>
        </div>
      </nav>
      <CustomNavbar />

      <div className={styles.teamsDisplay}>
        {teams.map((team, index) => (
          <div key={index} className={`${styles.teamCard} ${expandedTeams[index] ? styles.expanded : ''}`}>
            <div className={styles.teamHeader} onClick={() => toggleTeamExpand(index)}>
              {/* Add the team logo in the header next to the team name */}
              {team.logo && (
                <img src={team.logo} alt={`${team.teamName} logo`} className={styles.teamLogoSmall} />
              )}
              <h5>{team.teamName}</h5>
              <img src={downArrow} alt="Expand" className={styles.downArrow} />
            </div>
            {expandedTeams[index] && (
              <div className={styles.teamDetails}>
                <div className={styles.playerList}>
                  <div className={styles.playerListHeader}>
                    <span>Player</span>
                    <span>Jersey No.</span>
                  </div>
                  {team.players.map((player, playerIndex) => (
                    <div key={playerIndex} className={styles.playerItem}>
                      <span className={styles.playerName}>{player.playerName}</span>
                      <span className={styles.playerPrice}>{player.jerseyNo}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}

      </div>

    </div>
  );
};


export default TeamsPage;