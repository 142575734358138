import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './../../TeamList.module.css';
import logo from '../../../assets/fanup_logo_white.png';
import downArrow from '../../../assets/downarrow.svg';
import CustomNavbar from "./../../CustomNavbar";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UpdateLive from './../../UpdatingLive';
import fetchWrapper from '../../../utils/fetchWrapper';

const AuctionPage = ({ tournamentName }) => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const [teams, setTeams] = useState([]);
  const [expandedTeams, setExpandedTeams] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!tournamentName) {
      console.error('No tournament name found');
      // navigate('/');
      return;
    }

    const fetchTournamentAndSheetData = async () => {
      try {
        setLoading(true);

        const tournamentResponse = await fetchWrapper(`${BACKEND_URL}/tournament/${tournamentName}`, {
          credentials: 'include',
        });

        if (tournamentResponse.ok) {
          const tournamentData = await tournamentResponse.json();
          const auctionSheetLink = tournamentData.auction;

          if (auctionSheetLink) {
            const sheetId = extractSheetIdFromLink(auctionSheetLink);
            const sheetName = 'Sheet1';
            const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;

            const sheetResponse = await fetchWrapper(url);

            if (sheetResponse.ok) {
              const csvData = await sheetResponse.text();
              const teamsData = parseCSVData(csvData);
              setTeams(teamsData);
              setLoading(false);
            } else {
              throw new Error('Failed to fetch Google Sheet data');
            }
          }
        } else {
          throw new Error('Failed to fetch tournament data');
        }
      } catch (error) {
        console.error('Error fetching tournament or Google Sheet data:', error);
      }
    };

    fetchTournamentAndSheetData();

    const interval = setInterval(() => {
      fetchTournamentAndSheetData();
    }, 30000);

    return () => clearInterval(interval);
  }, [navigate, tournamentName, tournamentName]);




  const extractSheetIdFromLink = (link) => {
    const match = link.match(/\/d\/([a-zA-Z0-9-_]+)/);
    return match ? match[1] : null;
  };


  const parseCSVData = (csv) => {
    const rows = csv.split('\n').slice(1); // Skip the first row (headers)
    const teams = [];
    let currentTeam = null;

    rows.forEach((row, index) => {
      const columns = row.split(',').map(col => col.trim().replace(/^"|"$/g, '')); // Trim spaces and remove surrounding quotes
      const teamName = columns[0] || (currentTeam ? currentTeam.teamName : null); // Use the previous team name if the current row doesn't have a team name
      const playerName = columns[1];
      const playerPrice = parseFloat(columns[2]);
      const teamLogo = columns[3] ? columns[3] : null; // Only set logo if it's available

      // If a new team starts, push the previous team and start a new one
      if (teamName && (!currentTeam || currentTeam.teamName !== teamName)) {
        if (currentTeam) {
          teams.push(currentTeam);
        }
        // Create a new team object
        currentTeam = {
          teamName,
          players: [],
          totalSpent: 0,
          budgetLeft: 120,
          logo: teamLogo, // Only assign a logo if present
        };
      }

      // Add player to the current team
      if (currentTeam && playerName && !isNaN(playerPrice)) {
        currentTeam.players.push({ name: playerName, price: playerPrice });
        currentTeam.totalSpent += playerPrice;
        currentTeam.budgetLeft = 120 - currentTeam.totalSpent;
      }

      // If it's the last row, push the last team into the array
      if (index === rows.length - 1 && currentTeam) {
        teams.push(currentTeam);
      }
    });

    return teams;
  };

  const toggleTeamExpand = (index) => {
    setExpandedTeams(prevExpandedTeams => ({
      ...prevExpandedTeams,
      [index]: !prevExpandedTeams[index]
    }));
  };

  return (
    <div className={styles.liveBudgetSheet}>
      {loading && <UpdateLive />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="FanUp Logo" className={styles.logo} />
          <span className={styles.registrationText}>LIVE BUDGET SHEET</span>
        </div>
      </nav>
      <CustomNavbar />

      <div className={styles.teamsDisplay}>
        {teams.map((team, index) => (
          <div key={index} className={`${styles.teamCard} ${expandedTeams[index] ? styles.expanded : ''}`}>
            <div className={styles.teamHeader} onClick={() => toggleTeamExpand(index)}>
              {/* Add the team logo in the header next to the team name */}
              {team.logo && (
                <img src={team.logo} alt={`${team.teamName} logo`} className={styles.teamLogoSmall} />
              )}
              <h5>{team.teamName}</h5>
              <img src={downArrow} alt="Expand" className={styles.downArrow} />
            </div>
            {expandedTeams[index] && (
              <div className={styles.teamDetails}>
                <div className={styles.playerList}>
                  <div className={styles.playerListHeader}>
                    <span>Player</span>
                    <span>Price</span>
                  </div>
                  {team.players.map((player, playerIndex) => (
                    <div key={playerIndex} className={styles.playerItem}>
                      <span className={styles.playerName}>{player.name}</span>
                      <span className={styles.playerPrice}>{player.price}M</span>
                    </div>
                  ))}
                </div>
                <div className={styles.budgetSummary}>
                  <p className={styles.moneyText}>Money Spent: <span>{team.totalSpent}M</span></p>
                  <p className={styles.moneyText}>Money Remaining: <span>{team.budgetLeft}M</span></p>
                </div>
              </div>
            )}
          </div>
        ))}

      </div>
    </div>
  );
};

export default AuctionPage;
