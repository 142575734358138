import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./components/LoginPage";
import SignupPage from "./components/SignupPage";
import SportsSelectionPage from "./components/SportsSelectionPage";
import CollegeDashboardPage from "./components/SportsDashboardPage";
import SportDetailPage from "./components/SportDetailPage";
import FantasyPage from "./components/FantasyPage";
import PrivateRoute from "./components/PrivateRoute";
import CreateTournament from "./components/CreateTournament";
import TournamentDashboard from "./components/TournamentDashboard";
import TournamentPage from "./components/TournamentPage";
import UserSignupPage from "./components/UserSignupPage";
import ViewTournamentsPage from "./components/ViewTournamentsPage";
import LiveBudgetSheet from "./components/LiveBudgetSheet";
import AuctionPage from "./components/AuctionPage";
import AdminVerification from "./components/AdminVerification";
import InterUniversitySignupPage from "./components/InterUniversitySignupPage"; // Import the new component
import "bootstrap/dist/css/bootstrap.min.css";
import TeamList from "./components/TeamList";
import TournamentAuctionPage from "./components/TournamentAuctionPage";
import TournamentSchedule from "./components/tournament/TournamentSchedule";
import TournamentResults from "./components/tournament/TournamentResults";

import "./assets/new-styles.css";
import TournamentStats from "./components/tournament/TournamentStats";
import TournamentTeams from "./components/tournament/TournamentTeams";

function App() {
  return (
    <Router>
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <div style={{ flex: "1" }}>
          <Routes>
            <Route path='/' element={<LoginPage />} />
            <Route path='/signup' element={<SignupPage />} />
            <Route path='/usersignup' element={<UserSignupPage />} />
            <Route
              path='/interuniversitysignup'
              element={<InterUniversitySignupPage />}
            />{" "}
            {/* New route */}
            <Route
              path='/dashboard'
              element={<PrivateRoute element={TournamentDashboard} />}
            />
            <Route
              path='/fantasy/:gameName'
              element={<PrivateRoute element={FantasyPage} />}
            />
            <Route
              path='/create-tournament'
              element={<PrivateRoute element={CreateTournament} />}
            />
            <Route
              path='/tournament/:tournamentName'
              element={<TournamentPage />}
            />
            <Route
              path='/tournaments/:tournamentName'
              element={<TournamentPage />}
            />
            <Route
              path='/tournaments'
              element={<ViewTournamentsPage />}
            />
            <Route
              path='/livebudgetsheet/:tournamentName'
              element={<LiveBudgetSheet />}
            />
            <Route
              path='/tournament/schedule/:tournamentId'
              element={<TournamentSchedule />}
            />
              <Route
              path='/tournament/results/:tournamentId'
              element={<TournamentResults />}
            />
            <Route
              path='/tournament/stats/:tournamentId'
              element={<TournamentStats />}
            />

            <Route
              path='/tournament/teams/:tournamentId'
              element={<TournamentTeams />}
            />

            <Route path='/teamlist/:tournamentName' element={<TeamList />} />

            <Route path='/auction' element={<AuctionPage />} />
            <Route
              path='/tournament-auction/:tournamentName'
              element={<TournamentAuctionPage />}
            />
            <Route
              path='/admin'
              element={<PrivateRoute element={AdminVerification} />}
            />
            <Route path='/sportselection/' element={<SportsSelectionPage />} />
            <Route
              path='/collegedashboard'
              element={<CollegeDashboardPage />}
            />
            <Route
              path='/updatesportdetails/:collegeName/:sportName'
              element={<SportDetailPage />}
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
