import React, { useState, useEffect, Fragment } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Papa from "papaparse";
import moment from "moment";

import styles from "./../TournamentPage.module.css";
import logo from "../../assets/fanup_logo_white.png";
import CustomNavbar from "./../CustomNavbar";
import UpdateLive from "./../UpdatingLive";
import fetchWrapper from "./../../utils/fetchWrapper";

const TournamentResults = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { tournamentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [result, setResultData] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [tournamentLogo, setTournamentLogo] = useState("");


  const [selectedSport, setSport] = useState("");
  const [selectedGroup, setGroup] = useState("");

  const [sports, setSports] = useState([]);
  const [groups, setGroups] = useState([]);

  const [itemRows, setItemRows] = useState({});

  const [filteredItems, setFilteredItems] = useState([]);
  const [groupedUniversityStats, setGroupedUniversityStats] = useState(new Map());

  const extractSheetIdFromLink = (link) => {
    const match = link.match(/\/d\/([a-zA-Z0-9-_]+)/);
    return match ? match[1] : null;
  };

  const parseCSVData = (csv) => {
    const parsedData = Papa.parse(csv, {
      header: true,
      skipEmptyLines: true,
      dynamicTyping: true,
    });
    const entries = [];
    for (const item of parsedData.data) {
      const hasValidTime = (dateStr) => {
        if (!dateStr) return false;
        return dateStr.includes(",") && dateStr.split(",")[1].trim().length > 0;
      };

      const fromHasTime = hasValidTime(item["From"]);
      const toHasTime = hasValidTime(item["To"]);

      let matchStartFrom = fromHasTime
        ? moment(item["From"], "MM/DD/YYYY, HH:mm")
        : item["From"]
          ? moment(item["From"], "MM/DD/YYYY")
          : null;

      let matchStartTo = toHasTime
        ? moment(item["To"], "MM/DD/YYYY, HH:mm")
        : item["To"]
          ? moment(item["To"], "MM/DD/YYYY")
          : null;

      let matchStatus = "scheduled";
      let now = moment();

      // Determine match status
      if (now.isAfter(matchStartTo)) {
        matchStatus = "ended";
      } else if (now.isBetween(matchStartFrom, matchStartTo, null, "[)")) {
        matchStatus = "started";
      }

      const formatDateDisplay = (momentObj, hasTime) => {
        if (!momentObj) return "";
        return hasTime
          ? momentObj.format("DD MMM YYYY, hh:mm A")
          : momentObj.format("DD MMM YYYY");
      };

      const startDateDisplay = formatDateDisplay(matchStartFrom, fromHasTime);
      const endDateDisplay = formatDateDisplay(matchStartTo, toHasTime);

      let timeToDisplay = "";

      if (matchStartFrom && matchStartTo) {
        if (matchStartFrom.isSame(matchStartTo, "day")) {
          if (fromHasTime && toHasTime) {
            timeToDisplay = `${matchStartFrom.format(
              "D MMM YYYY"
            )}, ${matchStartFrom.format("hh:mm A")} - ${matchStartTo.format(
              "hh:mm A"
            )}`;
          } else {
            timeToDisplay = matchStartFrom.format("D MMM YYYY");
          }
        } else {
          timeToDisplay = endDateDisplay
            ? `${startDateDisplay} - ${endDateDisplay}`
            : startDateDisplay;
        }
      } else if (matchStartFrom) {
        timeToDisplay = startDateDisplay;
      }

      const startDate = matchStartFrom
        ? matchStartFrom.format("DD MMM YYYY")
        : "";

      if (
        item["University 1"] != null
        && item["University 2"] != null
        && item["University 1 Score"] != null
        && item["University 2 Score"] != null
      ) {
        entries.push({
          univerisityOne: item["University 1"],
          univerisityTwo: item["University 2"],
          sportName: item["Sport Name"],
          univerisityOneScore: item["University 1 Score"],
          univerisityTwoScore: item["University 2 Score"],
          group: item["Group"],
          time: timeToDisplay,
          startDate: startDate,
          status: matchStatus,
        });
      }
    }
    return entries;
  };

  useEffect(() => {

    if (!tournamentId) {
      console.error("No tournament found");
      navigate("/");
      return;
    }

    const fetchTournamentAndSheetData = async () => {
      try {
        setLoading(true);
        const tournamentResponse = await fetchWrapper(
          `${BACKEND_URL}/tournament/${tournamentId}`,
          {
            credentials: "include",
          }
        );
        if (tournamentResponse.ok) {
          const tournamentData = await tournamentResponse.json();
          //change sheet thing here
          setTournamentLogo(tournamentData.logo);
          let sheetLink = tournamentData.schedule;
          //just for demo
          // sheetLink = "https://docs.google.com/spreadsheets/d/1_JQ_KmAgdQJjZgztvYjN3kY9fXKo_Rdfz0wifRE5hX8/edit?gid=0#gid=0";

          if (sheetLink) {
            const sheetId = extractSheetIdFromLink(sheetLink);
            const sheetName = "Sheet1";
            const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?tqx=out:csv&sheet=${sheetName}`;
            const sheetResponse = await fetchWrapper(url);
            if (sheetResponse.ok) {
              const csvData = await sheetResponse.text();
              setAllItems(parseCSVData(csvData));
              setLoading(false);
            } else {
              throw new Error("Failed to fetch Google Sheet data");
            }
          }
        } else {
          throw new Error("Failed to fetch tournament data");
        }
      } catch (error) {
        console.error("Error fetching tournament or Google Sheet data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentAndSheetData();

    const interval = setInterval(() => {
      fetchTournamentAndSheetData();
    }, 30000);

    return () => clearInterval(interval);
  }, [navigate, location]);

  const organizeSportsData = (matches) => {
    const sportsTemp = [];
    const groupsTemp = [];
    matches.forEach(match => {
      const { sportName, group } = match;
      const sportKey = sportName.trim().toLowerCase();
      const groupKey = group ? group.trim().toUpperCase() : "UNGROUPED";
      if (!sportsTemp.includes(sportKey)) {
        sportsTemp.push(sportKey);
      }
      if (!groupsTemp.includes(groupKey)) {
        groupsTemp.push(groupKey);
      }
    });
    setSports(sportsTemp);
    setGroups(groupsTemp);
    applyFilters();
  }

  useEffect(() => {
    if (sports.length > 0) {
      if (selectedSport === "") {
        setSport(sports[0]);
      }
    }
  }, [sports]);

  const applyFilters = () => {
    if (!allItems || allItems.length === 0) return;

    const filtered = [];
    const groupStatsMap = new Map();

    allItems.forEach(match => {
      const { sportName, group, univerisityOne, univerisityTwo, univerisityOneScore, univerisityTwoScore } = match;

      const sportKey = sportName.trim().toLowerCase();
      const groupKey = group ? group.trim().toUpperCase() : "UNGROUPED";

      let add = true;

      if (selectedSport !== "" && selectedSport !== sportKey) {
        add = false;
      }

      if (selectedGroup !== "" && selectedGroup !== groupKey) {
        add = false;
      }

      if (add) {
        filtered.push(match);

        // Initialize group if not present
        if (!groupStatsMap.has(groupKey)) {
          groupStatsMap.set(groupKey, new Map());
        }
        const universityStats = groupStatsMap.get(groupKey);

        // Function to update stats
        const updateStats = (university, scoreFor, scoreAgainst) => {
          if (!universityStats.has(university)) {
            universityStats.set(university, { TP: 0, W: 0, L: 0, PF: 0, PA: 0, PD: 0 });
          }

          let stats = universityStats.get(university);
          stats.TP += 1; // Increment score
          stats.PF += scoreFor; // Points For
          stats.PA += scoreAgainst; // Points Against
          stats.PD = stats.PF - stats.PA; // Points Difference

          if (scoreFor > scoreAgainst) {
            stats.W += 1; // Increment wins
          } else {
            stats.L += 1; // Increment losses
          }
        };

        // Update stats for both universities
        updateStats(univerisityOne, univerisityOneScore, univerisityTwoScore);
        updateStats(univerisityTwo, univerisityTwoScore, univerisityOneScore);
      }
    });

    const groupedStatsArray = Array.from(groupStatsMap.entries()).map(([group, universities]) => ({
      group,
      universities: Array.from(universities.entries())
        .map(([university, stats]) => ({ university, ...stats }))
        .sort((a, b) => b.W - a.W || b.PD - a.PD)  // Sort by Wins first, then by PD if Wins are the same
    }));

    console.log(groupedStatsArray, "Grouped University Stats");

    // Update state
    setFilteredItems(filtered);
    setGroupedUniversityStats(groupedStatsArray);
  };


  useEffect(() => {
    if (allItems && allItems.length > 0) {
      console.log(allItems, 'allItems');
      organizeSportsData(allItems);
    }
  }, [allItems]);

  return (
    <div className={`${styles.tournamentPage} ss-page`}>
      {loading && <UpdateLive loadingTxt='Refreshing...' />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt='FanUp Logo' className={styles.logo} />
          <span className={styles.registrationText}>Results</span>
        </div>
        {tournamentLogo ? (
          <>
            <img
              className={styles.tournamentLogo}
              src={tournamentLogo}
              alt='Tournament Logo'
            />
          </>
        ) : null}
      </nav>
      <CustomNavbar />

      <div className={`${styles.dashboardGrid} ss-box-container`}>
        <div className='ss-box'>
          <div className='ss-box-filters'>

            {/* Sports Filter */}
            <div className='filter-item'>
              <select
                value={selectedSport}
                onChange={(e) => setSport(e?.target?.value ?? "")}
                className='form-control'
              >
                <option value=''>All Sports</option>
                {sports.map((sport, index) => {
                  return (
                    <option key={index} value={sport}>
                      {sport}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* Groups Filter */}
            <div className='filter-item'>
              <select
                value={selectedGroup}
                onChange={(e) => setGroup(e?.target?.value ?? "")}
                className='form-control'
              >
                <option value=''>All Groups</option>
                {groups.map((group, index) => {
                  return (
                    <option key={index} value={group}>
                      {group}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className='filter-item'>
              <button onClick={applyFilters} className='ss-box-filter-btn'>
                Submit
              </button>
            </div>
          </div>

          <div className='ss-box-content'>
            {groupedUniversityStats.length > 0 ? (
              groupedUniversityStats.map(({ group, universities }) => (
                <div key={group} className="group-section fanup-table-main">
                  <h3 className="result-group-name">Group: {group}</h3>
                  <table className="fanup-table fanup-score-table table table-bordered">
                    <thead>
                      <tr>
                        <th>University</th>
                        <th>TP</th>
                        <th>W</th>
                        <th>L</th>
                        <th>PF</th>
                        <th>PA</th>
                        <th>PD</th>
                      </tr>
                    </thead>
                    <tbody>
                      {universities.map(({ university, TP, W, L, PF, PA, PD }) => (
                        <tr key={university}>
                          <td>{university}</td>
                          <td>{TP}</td>
                          <td>{W}</td>
                          <td>{L}</td>
                          <td>{PF}</td>
                          <td>{PA}</td>
                          <td>{PD}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))
            ) : (
              <p className='text-center'>No Data Found</p>
            )}

          </div>

        </div>
      </div>

    </div >
  );
};

export default TournamentResults;

