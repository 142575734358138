import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import styles from './../TeamList.module.css';
import UpdateLive from './../UpdatingLive';

import fetchWrapper from "../../utils/fetchWrapper";
import AuctionPage from "./teams/AuctionPage";
import TeamsPage from "./teams/TeamsPage";

const TournamentTeams = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const { tournamentId } = useParams();

  const [loading, setLoading] = useState(false);
  const [tournamentData, setTournamentData] = useState(null);

  useEffect(() => {
    if (!tournamentId) {
      console.error("No tournament found");
      navigate("/");
      return;
    }

    const fetchTournamentAndSheetData = async () => {
      try {
        setLoading(true);
        const tournamentResponse = await fetchWrapper(
          `${BACKEND_URL}/tournament/${tournamentId}`,
          {
            credentials: "include",
          }
        );
        if (tournamentResponse.ok) {
          const tournamentData = await tournamentResponse.json();
          setTournamentData(tournamentData);
        } else {
          throw new Error("Failed to fetch tournament data");
        }
      } catch (error) {
        console.error("Error fetching tournament or Google Sheet data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTournamentAndSheetData();

  }, [navigate, location]);

  return (
    <>
      <div className={styles.liveBudgetSheet}>
        {loading && <UpdateLive />}
        {
          tournamentData?._id ? <>
            {
              tournamentData?._id === "6799ae78cc3c4580437321f2" ? <>
                <AuctionPage tournamentName={tournamentData?.tournamentName} />
              </> : <>
                <TeamsPage tournamentId={tournamentData?._id} />
              </>
            }
          </> : null
        }
      </div>
    </>
  );
};


export default TournamentTeams;